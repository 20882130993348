import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { getPageProductType } from "api/ProductTypeApi";
import { getAllProductCate, getPageProductCate, ProductCateCountApi } from "api/ProductCategoryApi";
import { ECommerce } from "api/AppConfigApi";
import SEO from "./Seo";

export const NavLink = tw.span`my-2 tracking-wide transition duration-300 text-black hover:text-custom-100  pb-2 lg:pb-5 no-underline border-b-4 border-transparent hover:border-custom-100 text-base lg:text-sm lg:mx-4 lg:my-0 xl:text-base xl:mx-6`;

export const LogoLink = styled(NavLink)`
  ${tw`py-2`};
  img {
    ${tw`mr-3`}
  }
`;

export default function CategorysMenu() {
  const [data, setData] = useState([]);
  const [count, setCount] = useState([]);
  const [lists, setLists] = useState([
    "gold", "silver", "diamond", "platinum"
  ]);
  const [state, setState] = useState({ isTechWalletEnable: false })

  useEffect(() => {
    getProductType();
    // getTechWalletConfigData();
    //eslint-disable-next-line
  }, []);

  let history = useNavigate();

  const getProductCount = (productId) => {
    let filter = {
      // visibilityMode: "MOBILE",
      metaProdTypeEnty: productId,
      // allowCatalogue: 1,
    };
    getAllProductCate(filter).then((data) => {
      const categoryData = data.response.filter((item) => item.visibilityMode === 'WEB_MOBILE' || item.visibilityMode === 'MOBILE')
      setCount(categoryData);
    });
  };

  // const getTechWalletConfigData = async () => {
  //   const appConfigResData = await ECommerce('techwallet_config');
  //   if (appConfigResData.response&& appConfigResData.response.moduleField) {
  //     const moduleData = JSON.parse(appConfigResData.response.moduleField);
  //     moduleData.forEach((item) => {
  //       if (item.key === "IS_TECHWALLET_ENABLED")
  //         setState({ isTechWalletEnable: item.value });
  //     });
  //   }
  // };
  // const getProductType = () => {
  //   getPageProductType({}).then((data) => {
  //     let response = data.response.content;
  //     let filterData = response.filter((data) => {
  //       if (data.metaProductCategoryList.length > 0) {
  //         const typeName = data.name.toLowerCase().trim();
  //         return (
  //           typeName === "gold" ||
  //           typeName === "silver" ||
  //           // typeName === "silver articles" ||
  //           typeName === "gift articles" ||
  //           typeName === "diamond" ||
  //           typeName === "platinum"
  //         );
  //       }
  //       else
  //       {
  //         return false
  //       }
  //     });
  //     filterData.reverse()
  //     setData(filterData);
  //   });
  // };

  const getProductType = () => {
    const arr1 = [];
    const arr2 = [];
    let isChecked = false
    let equlprod = []
    let notEqulprod = []

    let filterData = {
      active: 1
    }
    getPageProductType(filterData).then((data) => {
      let response = data.response.content;
      lists.forEach((item, idx) => {
        if (!isChecked) {
          equlprod.length = 0
          arr1.push(...response.filter((x) => x.name.toLowerCase() === item))
          arr2.push(...response.filter((x) => x.name.toLowerCase() !== item))
          isChecked = true
        } else {
          arr1.push(...arr2.filter((x) => x.name.toLowerCase() === item))
          const newarr = arr2
          notEqulprod = newarr.filter((x) => (x.name).toLowerCase() !== item)
          arr2.length = 0
          arr2.push(...notEqulprod)
        }
        if (lists.length - 1 === idx) {
          arr1.push(...arr2)
        }
      })
      // const pageData =  response.reverse()
      // let cropData= pageData.slice(0,5)
      let cropData = arr1.slice(0, 6)
      setData(cropData);
    })
  }

  return (
    <>
      <SEO
        title='AMR Sumangalee Jewellers'
        description='Beginner friendly page for learning React Helmet.'
        name='Company name.'
        type='article' />
      <div className="ptSans collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mx-auto mb-2 mb-lg-0 header">
          {data.map((data, idx) => {
            return (
              <li
                key={idx}
                className="nav-item dropdown"
                onMouseEnter={() => getProductCount(data.id)}
                onMouseLeave={() => setCount(0)}
              >
                <div
                  className="nav-link ps-4 text-uppercase"
                  id="navbarDropdown"
                  role="button"
                  // data-bs-toggle={`${count.length > 0 ? "dropdown" : ""}`}
                  aria-expanded="false"
                  style={{ whiteSpace: "nowrap" }}
                  onClick={() => history('/ProductList', { state: { type: data } })}
                >
                  {data.name}
                </div>
                {count.length > 0 ? (
                  <div
                    className="dropdown-menu py-3 rounded border"
                    aria-labelledby="navbarDropdown"
                  >
                    <ul>
                      {count.map((category, index) => (
                        <motion.li
                          key={index}
                          className="d-flex align-items-center"
                          initial={{
                            y: 300,
                            x: 0,
                            opacity: 0,
                          }}
                          animate={{
                            y: 0,
                            x: 0,
                            opacity: 1,
                          }}
                          exit={{
                            opacity: 0,
                          }}
                        >
                          <motion.a
                            className="dropdown-item"
                            style={{
                              whiteSpace: "normal",
                              cursor: "pointer",
                            }}
                            whileHover={{ scale: 1.1, originX: 0, fontWeight: 700 }}
                            transition={{ type: "spring", stiffness: 300 }}
                            layout
                            onClick={() => {
                              history("/ProductList", {
                                state: { category: category },
                              });
                            }}
                          >
                            {category.categoryName}
                          </motion.a>
                          {/* <span className="badge bg-success mx-1" style={{fontSize:"10px"}}>
                          {category.itemCount}
                        </span> */}
                        </motion.li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div
                    className="dropdown-menu py-3 w-25 opacity-0"
                    aria-labelledby="navbarDropdown"
                  >
                    <ul className="d-block w-100">
                      <li className="w-100">
                        <span
                          className="dropdown-item"
                          style={{
                            whiteSpace: "normal",
                            cursor: "pointer",
                          }}
                        >
                          No - data
                        </span>
                      </li>
                    </ul>
                  </div>
                )}
              </li>
            );
          })}
          {data.length > 0 && (
            <li className="nav-item dropdown">
              <Link className="nav-link px-4 text-uppercase" to="/SchemeList">
                Saving scheme
              </Link>
            </li>
          )}
          {state.isTechWalletEnable ? (
            <li className="nav-item dropdown">
              <Link className="nav-link px-4 text-uppercase" to="/TechWallet">
                Tech Wallet
              </Link>
            </li>
          ) : (null)}
        </ul>
      </div>
    </>
  );
}


// <nav className="navbar bg-light fixed-top">
//   <div className="container-fluid">
//     <a className="navbar-brand" href="#">
//       Offcanvas navbar
//     </a>
//     <button
//       className="navbar-toggler"
//       type="button"
//       data-bs-toggle="offcanvas"
//       data-bs-target="#offcanvasNavbar"
//       aria-controls="offcanvasNavbar"
//     >
//       <span className="navbar-toggler-icon" />
//     </button>
//     <div
//       className="offcanvas offcanvas-end"
//       tabIndex={-1}
//       id="offcanvasNavbar"
//       aria-labelledby="offcanvasNavbarLabel"
//     >
//       <div className="offcanvas-header">
//         <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
//           Offcanvas
//         </h5>
//         <button
//           type="button"
//           className="btn-close"
//           data-bs-dismiss="offcanvas"
//           aria-label="Close"
//         />
//       </div>
//       <div className="">
//         <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
//           <li className="nav-item">
//             <a className="nav-link active" aria-current="page" href="#">
//               Home
//             </a>
//           </li>
//           <li className="nav-item">
//             <a className="nav-link" href="#">
//               Link
//             </a>
//           </li>
//           <li className="nav-item dropdown">
//             <a
//               className="nav-link dropdown-toggle"
//               href="#"
//               role="button"
//               data-bs-toggle="dropdown"
//               aria-expanded="false"
//             >
//               Dropdown
//             </a>
//             <ul className="dropdown-menu">
//               <li>
//                 <a className="dropdown-item" href="#">
//                   Action
//                 </a>
//               </li>
//               <li>
//                 <a className="dropdown-item" href="#">
//                   Another action
//                 </a>
//               </li>
//               <li>
//                 <hr className="dropdown-divider" />
//               </li>
//               <li>
//                 <a className="dropdown-item" href="#">
//                   Something else here
//                 </a>
//               </li>
//             </ul>
//           </li>
//         </ul>
//         <form className="d-flex mt-3" role="search">
//           <input
//             className="form-control me-2"
//             type="search"
//             placeholder="Search"
//             aria-label="Search"
//           />
//           <button className="btn btn-outline-success" type="submit">
//             Search
//           </button>
//         </form>
//       </div>
//     </div>
//   </div>
// </nav> 
