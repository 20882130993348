import { memo, useEffect, useState } from "react";
import tw from "twin.macro";
import TopbarMenus from "./TopbarMenus";
import { ECommerce } from "api/AppConfigApi";
import { setBranchId } from "../../utility/HeaderConfig.js";
import { useNavigate } from "react-router-dom";
import { useCartContext } from "context/UseCartContext";
// import { reactLocalStorage } from "reactjs-localstorage";

const TopbarContainer = tw.div`text-black `;

function Topbar({ branch }) {
  const [media, setMedia] = useState([]);
  const [branches, setBranches] = useState([]);

  const { dispatch } = useCartContext();

  let history = useNavigate();

  useEffect(() => {
    getAllBrachDetails();
    // socialMedia();
    // reactLocalStorage.setObject('branchName',branch.branchName)
    // eslint-disable-next-line
  }, []);

  const getAllBrachDetails = () => {
    const moduleName = "endpoint";
    ECommerce(moduleName).then((data) => {
      const branchResponse = JSON.parse(data.response.moduleField);
      setBranches(branchResponse);
    });
  };

  const socialMedia = () => {
    let filter = "social_media_config";
    ECommerce(filter).then((data) => {
      let SOCIALMEDIA = JSON.parse(data.response.moduleField);
      setMedia(SOCIALMEDIA);
    });
  };

  const selectBranch = (branchId) => {
    setBranchId(branchId);
    dispatch({ type: "BRANCHID", payload: branchId });
    history("/");
    // reactLocalStorage.setObject('branchName',branch.branchName)

  };


  return (
    <TopbarContainer style={{ background: '#f9e8e1' }}>
      <div tw="max-w-full px-4 sm:px-6 lg:px-8 m-auto flex items-center justify-center sm:justify-between h-10" className="ptSans">
        {/* Increased flex-grow to occupy more space */}
        <div tw="xl:flex-[2] lg:flex-grow lg:inline-flex hidden items-center">
          <i className="bi bi-shop" tw="text-xl font-semibold"></i>
          <span
            className="hover-trigger ms-2"
            tw="text-xs sm:text-sm lg:text-xs xl:text-sm relative cursor-pointer"
          >
            Branch : {branch.branchName}

          </span>
          <span tw="hidden sm:flex text-center flex-[0.3]"></span>
          <span
            className="hover-trigger ms-2 flex items-center justify-center mt-4 ml-3 mb-0"
            tw="text-xs sm:text-sm lg:text-xs xl:text-sm relative cursor-pointer"
          >
            <p tw="no-underline hocus:px-1 pb-1 duration-500 text-gray-900 hover:text-custom-100">
              <i class="bi bi-phone"></i>
              <a href="tel:9543300006" tw="text-[#161616] hover:text-custom-100"> Gold: 95433 00006 </a>  |
              <a href="tel:9080512246" tw="text-[#161616] hover:text-custom-100"> Silver: 90805 12246 </a>  |
              <a href="tel:9092120022" tw="text-[#161616] hover:text-custom-100"> Chit: 90921 20022 </a>
            </p>
          </span>

        </div>

        {/* Reduced flex-grow to occupy less space */}
        {/* <div tw="hidden sm:flex text-center flex-[0.3]">
         {media.map((data, index) => (
           <a href={data.value} tw="text-black" target="blank" key={index}>
             <i
               className={`bi bi-${data.key?.toLowerCase()}`}
               tw="text-base mx-2 md:mx-4 hover:text-rose-500 cursor-pointer"
             ></i>
           </a>
         ))}
       </div> */}

        <TopbarMenus />
      </div>

    </TopbarContainer>
  );
}

export default memo(Topbar);
