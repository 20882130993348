import axios from 'axios';
import { BASE_URL } from '../utility/UrlConstants';
import { getHeaderConfig } from '../utility/HeaderConfig';


const getProduct = (size , filters: Object) => {
  return axios
    .get(
      `${BASE_URL}/products/get-product?page=0&size=${size}&sort=availableWeightAfterProcess,desc&productFilter=${JSON.stringify(
        filters
      )}`,
      getHeaderConfig()
    )
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};

const populateProduct = (filters: Object) => {
  return axios
    .get(`${BASE_URL}/products/get-calc?productFilter=${JSON.stringify(
      filters
    )}`, getHeaderConfig())
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};


const orderStatus = (filters: Object) => {
  return axios
    .get(`${BASE_URL}/admin/others/orderstatus/get-all?filters=${JSON.stringify(
      filters
    )}`, getHeaderConfig())
    .then(response => {
      const respData = {
        responseCode: response.status,
        response: response.data
      };
      return respData;
    })
    .catch(error => {
      console.log(error);
      throw error;
    });
};


const getProductStatusData = (id: String) => {
  return axios
    .get(`${BASE_URL}/products/get-status?id=${id}`, getHeaderConfig())
    .then((response) => {
      const respData = {
        responseCode: response.status,
        response: response.data,
      };
      return respData;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};


export { getProduct, populateProduct , orderStatus, getProductStatusData};
